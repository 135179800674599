import React, { FC, useContext, useRef } from 'react';
import useAsyncEffect from 'use-async-effect';
import styled from 'styled-components';
import { IoEyeOutline } from 'react-icons/io5';
import { PageLayout } from '@/components/page-layout';
import { Theme, useShopifyApi } from '@/webapi/use-shopify-api';
import { Card } from '@/components/card';
import { maybe } from '@/features/details/utils';
import { HSpace, VSpace } from '@/components/spacing';
import { BigButton } from '@/components/big-button';
import { AccountContext } from '@/features/account-context';
import { useComplexState } from '@/utils/use-complex-state';
import { Pagination } from '@/components/pagination';
import { GradientModal } from '@/components/gradient-modal';
import { centered } from '@/components/use-shared-element';
import { Spinner } from '@/Spinner';

export const Themes: FC = () => {
  const {
    listThemes,
    loading,
    themesStatus,
    injectSdk,
    removeSDK,
    removeSDKFromThemes,
  } = useShopifyApi(true);
  const ctx = useContext(AccountContext);
  const alias = ctx?.account?.store?.alias;
  const [state, setState] = useComplexState<{
    status: any;
    pages: Array<Array<Theme>>;
    page: number;
    themeIdToDelete: number;
    init: boolean;
  }>({
    init: false,
    themeIdToDelete: 0,
    page: 0,
    pages: [],
    status: {},
  });
  useAsyncEffect(
    async (isActive) => {
      const list = await listThemes();
      const chunks = sortedChunks(list);
      const themes = chunks?.[state.page]?.map((x: any) => x.id);
      if (!themes) {
        return;
      }
      const status = await themesStatus(themes);
      if (isActive) {
        setState((draft) => {
          status?.forEach((value, key) => {
            draft.status[key] = value;
          });
          draft.pages = chunks;
          draft.init = true;
        });
      }
    },
    [state.page],
  );
  const ref = useRef();

  if (!state.init) {
    return (
      <SpinnerWrapper>
        <Spinner size={100} />
      </SpinnerWrapper>
    );
  }

  const onDeleteSDK = async () => {
    if (state.themeIdToDelete === -1) {
      const recentThemes =
        state.pages
          ?.flatMap?.((a) => a)
          ?.slice?.(0, 12)
          ?.map((t) => t.id) || [];
      if (recentThemes.length > 0) {
        await removeSDKFromThemes(recentThemes);
      }
      setState((draft) => {
        draft.themeIdToDelete = 0;
        recentThemes.forEach((x) => {
          draft.status[x] = false;
        });
      });
      return;
    }
    await removeSDK(`${state.themeIdToDelete}`);
    setState((draft) => {
      draft.status[draft.themeIdToDelete] = false;
      draft.themeIdToDelete = 0;
    });
  };
  return (
    <PageLayout
      hideBackButton={false}
      heading="Theme Management"
      description="Run Visually on the themes you want to run experiences on"
    >
      <div ref={ref} />
      <GradientModal
        closeTop="0.5rem"
        closeRight="2.5rem"
        fromStyle={{
          borderRadius: `5rem`,
          backgroundColor: `#DEDEDE`,
          padding: `2rem 0 0 0`,
        }}
        toStyle={{
          borderRadius: `1rem`,
          backgroundColor: `#FFFFFF`,
          padding: `2rem 0 0 0`,
        }}
        isVisible={state.themeIdToDelete !== 0}
        showBackdrop
        targetPosAndSize={centered(27, 45)}
        fromRef={ref}
        overflowTop={{ heightInRem: 8 }}
        overflowBottom={{ heightInRem: 8, turnPointInPercent: 30 }}
        onClose={() =>
          setState((draft) => {
            draft.themeIdToDelete = 0;
          })
        }
        header={<p>ARE YOU SURE ?</p>}
        footer={
          <Footer>
            <BigButton
              disabled={loading}
              color="#a8b1b9"
              border="1px solid #e6e6e6"
              background="#ffffff"
              noTransform
              noShadow
              fillWidth
              type="button"
              size="large"
              onClick={onDeleteSDK}
            >
              YES
            </BigButton>
            <HSpace value={2} />
            <BigButton
              disabled={loading}
              color="#a8b1b9"
              border="1px solid #e6e6e6"
              background="#ffffff"
              noTransform
              noShadow
              fillWidth
              type="button"
              size="large"
              onClick={() => {
                setState((draft) => {
                  draft.themeIdToDelete = 0;
                });
              }}
            >
              NO
            </BigButton>
          </Footer>
        }
      >
        <VSpace value={4} />
        <RmWarning>
          Removing Visually SDK will stop all experiences
          {state.themeIdToDelete > 0 ? `running on this theme` : ``}.
        </RmWarning>
      </GradientModal>
      {state.pages?.[state.page]?.map((theme) => (
        <Container key={theme.id}>
          <Colum>
            <NameWrapper>
              <Name>{theme.name}</Name>
              <HSpace />
              <Active style={{ opacity: theme.role === `main` ? 1 : 0 }}>
                ACTIVE
              </Active>
            </NameWrapper>

            <VSpace value={1.5} />
            <DateWrap>
              Last Change{` `}
              {maybe(() => new Date(theme.updated_at)?.toLocaleString(), ``)}
            </DateWrap>
          </Colum>

          <Controls>
            <Anchor
              href={maybe(
                () =>
                  `https://${alias
                    .replace(`_`, `-`)
                    .toLowerCase()}.myshopify.com?preview_theme_id=${theme.id}`,
              )}
              target="_blank"
              rel="noreferrer"
            >
              <IoEyeOutline size={24} color="#bfbfbf" />
            </Anchor>
            <HSpace value={4} />
            {state.status?.[theme.id] ? (
              <BigButton
                disabled={loading}
                color="#a8b1b9"
                border="1px solid #e6e6e6"
                background="#ffffff"
                noTransform
                noShadow
                fillWidth
                type="button"
                size="medium"
                onClick={() => {
                  setState((draft) => {
                    draft.themeIdToDelete = theme.id;
                  });
                }}
              >
                Remove Visually from Theme
              </BigButton>
            ) : (
              <BigButton
                disabled={loading}
                color="white"
                border="1px solid #3451fa"
                background="#3451fa"
                noTransform
                fillWidth
                type="button"
                size="medium"
                onClick={async () => {
                  await injectSdk(`${theme.id}`);
                  setState((draft) => {
                    draft.status[theme.id] = true;
                  });
                }}
              >
                Install Visually to Theme
              </BigButton>
            )}
          </Controls>
        </Container>
      ))}
      <VSpace value={6} />
      <Pagination
        value={state.page + 1}
        pageCount={state.pages.length}
        pageSize={6}
        total={state.pages.reduce((a, b) => a + b.length, 0)}
        loading={loading}
        onPageChanged={(page: number) => {
          setState((draft) => {
            draft.page = page - 1;
          });
        }}
        label="themes"
      />
      <VSpace value={6} />
      <RmFromAllContainer>
        <Col>
          <RmTitle>Remove Visually from All themes</RmTitle>
          <VSpace />
          <RmDesc>
            In order to avoid residual code, perform this operation before
            completely deleting the application through Shopify
          </RmDesc>
          <VSpace />
          <RmSlim>Caution! this will stop all experiences.</RmSlim>
        </Col>
        <HSpace value={2} />
        <RmAllBtnWrap>
          <BigButton
            disabled={loading}
            color="white"
            border="1px solid red"
            background="red"
            noTransform
            noShadow
            fillWidth
            type="button"
            size="large"
            onClick={() => {
              setState((draft) => {
                draft.themeIdToDelete = -1;
              });
            }}
          >
            Remove now
          </BigButton>
        </RmAllBtnWrap>
      </RmFromAllContainer>
    </PageLayout>
  );
};

function sortedChunks(list: Array<Theme>) {
  return chunkArray(
    list.sort((a, b) =>
      maybe(
        () =>
          new Date(b.updated_at).valueOf() - new Date(a.updated_at).valueOf(),
        0,
      ),
    ),
    6,
  );
}

function chunkArray(array, chunkSize) {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunkedArray.push(array.slice(i, i + chunkSize));
  }
  return chunkedArray;
}
const RmAllBtnWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
`;
const Anchor = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.5;
  }
`;
const RmDesc = styled.span`
  color: #606d79;
  font-family: Inter, serif;
  font-size: 1.4rem;
  text-align: start;
`;
const RmTitle = styled.span`
  color: #606d79;
  font-family: Inter, serif;
  font-size: 1.6rem;
  font-weight: bolder;
  text-align: start;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;
const Controls = styled.div`
  display: flex;
  align-items: center;
`;
const RmWarning = styled.div`
  color: red;
  font-weight: bolder;
  font-family: Inter, serif;
  font-size: 1.6rem;
  text-align: center;
  width: 100%;
`;
const RmSlim = styled.div`
  color: red;
  font-family: Inter, serif;
  font-size: 1.4rem;
  text-align: start;
`;
const Active = styled.div`
  height: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-weight: bolder;
  font-size: 1.2rem;
  background-color: #15d170;
  padding: 1rem;
  border-radius: 2.5rem;
`;
const Footer = styled.div`
  display: flex;
`;

const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Colum = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
`;

const DateWrap = styled.span`
  color: #a6afb8;
  font-family: Inter, serif;
  font-size: 1.4rem;
`;
const NameWrapper = styled.div`
  display: flex;
`;
const Name = styled.div`
  font-size: 1.6rem;
  font-family: Inter, serif;
  font-weight: bold;
`;
const RmFromAllContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 900px;
  cursor: default;
  padding: 3.5rem;
  border: 2px solid #e0e0e2;
  border-radius: 1rem;
`;
const Container = styled(Card)`
  && {
    justify-content: space-between;
    flex-direction: row;
    margin-top: 2rem;
    width: 900px;
    position: relative;
    cursor: default;
    padding: 3.5rem;
    border-radius: 2rem;
  }
`;
